import React from 'react'
import PropTypes from 'prop-types'
import styled from 'react-emotion'

import Layout from '../components/layout'

const MobileContainer = styled('div')`
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    padding: 50px;
    width: 1024px;
  }

  @media screen and (max-width: 1023px) {
    padding: 20px;
    width: 100%;
  }
`

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <MobileContainer>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </MobileContainer>
  </Layout>
)

NotFoundPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

export default NotFoundPage
